import {useEffect, useRef} from 'react';
import {useNavigate} from "react-router";
import {useSetRecoilState} from "recoil";
import {claimInsuranceValue} from "../../recoil/store";
import {requestDelete, requestGet, requestPost} from "../../services/request";
import * as S from './ModalStyle'
import {setCookie} from "../../services/loginCookie";

function Modal(props) {

    const {
        title,
        type,
        handleModal,
        subTitle,
        handleAuthSuccess,
        authCode,
        handleModal2,
        nicepay,
        usedDealId,
        goodsId,
        setStep,
        caution,
        trInsId,
        handleCancelModal2,
        handleCancelModal3,
        setModalMessage,
        setCompanionList,
        inputType,
        setInputType,
        setShowModal2
    } = props


    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.cssText = `
    position: fixed; 
    top: -${window.scrollY * 0.1}rem;
    overflow-y: scroll;
    width: 100%;`;
        return () => {
            const scrollY = document.body.style.top;
            document.body.style.cssText = '';
            window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
        };
    }, []);

    const setClaimVal = useSetRecoilState(claimInsuranceValue)

    const changeState = async (state) => {
        const res = await requestPost(`/api/user/usedDeal/product/${usedDealId}/${state}`, null)
        if (res.code === 0) {
            window.location.reload();
        }
    }

    const deleteWish = async () => {
        const res = await requestDelete(`/api/user/usedDeal/favorite/${usedDealId}/delete`)
        if (res.code === 0) {
            window.location.reload()
        }
    }

    const cookieSetting = (autoLoginCheck) => {
        if (autoLoginCheck === 'y') {
            setCookie('wid_ys', 1004, {
                path: '/',
                // maxAge: 60 * 2

                // 아래는 라이브서버에서
                maxAge: 60 * 60 * 24 * 15
            })
        } else {
            setCookie('wid_ys', 1004, {
                path: '/',
                // maxAge: 60 * 2

                // 아래는 라이브서버에서
                maxAge: 60 * 60 * 24
            })
        }
    }


    const handleLogout = async () => {
        try {
            const res = await requestGet('/user/logout');

            if (res.code === 0) {

                // removeCookie('wid_ys')
                if (localStorage.getItem('autoLogin')) {
                    cookieSetting(localStorage.getItem('autoLogin'))
                    localStorage.removeItem('autoLogin');
                    localStorage.removeItem('whether');
                } else {
                    cookieSetting(sessionStorage.getItem('autoLogin'))
                    sessionStorage.removeItem('autoLogin');
                    sessionStorage.removeItem('whether');
                }

                window.location.href = '/'
            }
        } catch (e) {
            console.log(e)
        }

    }

    const cancelTrIns = async (trInsId) => {
        const res = await requestPost(`/mypage/tinfo/cancel/${trInsId}`, '')
        if (res.code === 0) {
            handleModal();
            handleCancelModal2()
        } else {
            setModalMessage(res.message);
            handleModal();
            handleCancelModal3()
        }
    }

    const modalRef = useRef(null);


    useEffect(() => {

        modalRef.current.focus(); // modalRef로 포커스 이동

    }, [])




    return (
        <>
            <S.Wrapper aria-hidden={true}
                       focusable={false}
                       tabIndex={-1}
                       onClick={() => {
                if (type === 'registerGoods') {
                    navigate('/useddeal?menu=salesdetails')
                } else if (type === 'overlap') {
                    navigate('/login')
                } else if (type === 'goodsDetail') {
                    navigate('/login', {
                        state: {
                            pathname: `/goodsDetail/${goodsId}`
                        }
                    })
                }else if (type === 'inquiry') {
                    navigate('/mypage/inquiryadmin?menu=history')
                    handleModal();
                    window.location.reload();
                }
                else if (type === 'prevStep1') {
                    handleModal2();
                } else {
                    handleModal();
                }
            }}/>
            <S.Container
                ref={modalRef}
                tabIndex={0}
                role={'dialog'}
                aria-modal={true}
                aria-labelledby="modal-title"
                aira-describedby="modal-desc">
                {(type === 'claimInsurance' ||
                    type === 'salesComplete' ||
                    type === 'signupInsurance' ||
                    type === 'step1' ||
                    type === 'prevStep1' ||
                    type === 'registerGoods' ||
                    type === 'groupJoin' ||
                    type === 'modifyGoods' ||
                    type === 'deleteGoodsImg' ||
                    type === 'application' ||
                    type === 'blackList' ||
                    type === 'phoneNumChange' ||
                    type === 'systemDown'
                )
                    ?
                    <>
                        {(type === 'claimInsurance' || type === 'application' || type === 'phoneNumChange') &&
                            <S.CheckIcon  aria-hidden={true} focusable={false}/>
                        }
                        <S.Title id="modal-title">
                            {title}
                        </S.Title>
                        <S.SubTitle id={'modal-desc'}>
                            {subTitle}
                        </S.SubTitle>
                    </>
                    : type === 'travelinsurancesuccess' ?
                        <>
                            <S.CheckIcon aria-hidden={true} focusable={false}/>
                            <S.Title id="modal-title">
                                {title}
                            </S.Title>
                        </>
                        : type === 'travelinsuranceadmin' ?

                            <>
                                <S.Title id="modal-title">
                                    {title}
                                </S.Title>
                                <S.SubTitle id={'modal-desc'}>
                                    {subTitle}
                                </S.SubTitle>
                                <S.Caution id={'modal-desc'}>
                                    {caution}
                                </S.Caution>
                            </>
                            :
                            <S.Title id="modal-title">
                                {title}
                            </S.Title>

                }
                <S.ButtonContainer>
                    {type === 'claimInsurance'
                        &&
                        <S.Confirm onClick={() => {
                            setClaimVal();
                            handleModal()
                            navigate('/mypage/insuranceadmin')
                        }}>
                            확인
                        </S.Confirm>
                    }
                    {
                        (
                            type === 'step1' ||
                            type === 'registerGoods' ||
                            type === 'inquiry' ||
                            type === 'groupJoin' ||
                            type === 'modifyGoods' ||
                            type === 'deleteGoodsImg' ||
                            type === 'overlap' ||
                            type === 'goodsDetail' ||
                            type === 'singupinsurance4' ||
                            type === 'blackList' ||
                            type === 'application' ||
                            type === 'prevStep1' ||
                            type === 'phoneNumChange' ||
                            type === 'travelinsurancesuccess' ||
                            type === 'travelinsurancefail' ||
                            type === 'trIns' ||
                            type === 'systemDown'
                        )
                        &&
                        <S.Confirm onClick={() => {
                            if (type === 'registerGoods') {
                                navigate('/useddeal?menu=salesdetails')
                            } else if (type === 'inquiry') {
                                navigate('/mypage/inquiryadmin?menu=history')
                                handleModal();
                                window.location.reload();
                            } else if (type === 'modifyGoods') {
                                navigate('/useddeal?menu=salesdetails')
                            } else if (type === 'overlap') {
                                navigate('/login')
                            } else if (type === 'goodsDetail') {
                                navigate('/login', {
                                    state: {
                                        pathname: `/goodsDetail/${goodsId}`
                                    }
                                })
                            } else if (type === 'singupinsurance4') {
                                window.location.reload();

                            } else if (type === 'application') {
                                navigate('/mypage/insuranceadmin')

                            } else if (type === 'prevStep1') {
                                handleModal2();

                            } else if (type === 'step1') {
                                navigate(-1)

                            } else if (type === 'phoneNumChange') {
                                navigate('/mypage')

                            } else if (type === 'travelinsurancefail') {
                                handleCancelModal3()

                            } else if (type === 'travelinsurancesuccess') {
                                handleCancelModal2()
                                navigate('/mypage/travelinsuranceadmin')
                            }
                            else {
                                handleModal();
                            }
                        }}>
                            확인
                        </S.Confirm>
                    }

                    {(type === 'signUpAuth' ||
                            type === 'logout' ||
                            type === 'addcompanion'
                        )
                        &&
                        <>
                            <S.Cancel onClick={() => {
                                if (type === 'logout') {
                                    handleModal()
                                } else if (type === 'addcompanion') {
                                    handleModal()
                                } else {
                                    handleModal();
                                    navigate('/login')
                                }

                            }}>
                                취소
                            </S.Cancel>
                            <S.Confirm onClick={() => {
                                if (authCode === 0 || authCode === 1012) {
                                    setStep(3)
                                    handleAuthSuccess();
                                } else if (type === 'logout') {
                                    handleLogout()
                                } else if (type === 'addcompanion') {
                                    setCompanionList([])
                                    setInputType(inputType)
                                    handleModal();
                                } else {
                                    handleModal();
                                }
                            }}>
                                확인
                            </S.Confirm>
                        </>
                    }

                    {(type === 'wishList' ||
                            type === 'salesDelete' ||
                            type === 'myinfoadmin'
                        )
                        &&
                        <>
                            <S.Confirm onClick={() => {
                                if (type === 'salesDelete') {
                                    changeState(3);
                                    handleModal();
                                } else if (type === 'wishList') {
                                    deleteWish();
                                } else if (type === 'salesCompelete') {
                                    changeState(1);
                                } else {
                                    handleModal();
                                }
                            }}>
                                삭제
                            </S.Confirm>
                            <S.Cancel onClick={() => {
                                handleModal();
                            }}>
                                취소
                            </S.Cancel>
                        </>
                    }
                    {type === 'findPw'
                        &&
                        <>
                            <S.Cancel onClick={() => {
                                handleModal();
                            }}>
                                닫기
                            </S.Cancel>
                            <S.Confirm onClick={() => {
                                navigate('/findid');
                            }}>
                                아이디찾기
                            </S.Confirm>
                        </>
                    }


                    {type === 'salesComplete'
                        &&
                        <>
                            <S.Confirm onClick={() => {
                                handleModal();
                                changeState(1);
                            }}>
                                거래완료하기
                            </S.Confirm>
                            <S.Cancel onClick={() => {
                                handleModal();
                            }}>
                                취소
                            </S.Cancel>
                        </>
                    }
                    {
                        type === 'signupInsurance'
                        &&
                        <>
                            <S.Cancel onClick={() => {

                                handleModal();

                            }}>
                                취소
                            </S.Cancel>
                            <S.Confirm onClick={() => {
                                if (type === 'signupInsurance') {
                                    handleModal();
                                    nicepay();
                                } else {
                                    handleModal();
                                }
                            }}>
                                가입하기
                            </S.Confirm>
                        </>
                    }

                    {
                        type === 'travelinsuranceadmin'
                        &&
                        <>
                            <S.Cancel onClick={() => {
                                handleModal();
                            }}>
                                닫기
                            </S.Cancel>
                            <S.Confirm onClick={() => {
                                // handleModal();
                                // handleModal2();
                                cancelTrIns(trInsId);
                            }}>
                                가입취소
                            </S.Confirm>
                        </>
                    }


                </S.ButtonContainer>
            </S.Container>
        </>
    )
}

export default Modal;

